import React from 'react';

import './text-callout.module.scss';

const TextCallout = ({ data }) => {
  const sliceLabel = data.slice_label;
  const is5050 = sliceLabel === '50-50';

  return (
    <div styleName={`text-callout text-callout--${sliceLabel}`}>
      <div className="o-container-fluid">
        <div className="o-row">
          {sliceLabel !== '50-50' && (
            <div className="o-col o-col-sm-md-7 o-col-lg--offset-1">
              <h2 className="title--large">
                {data.primary.portfolio_title.text}
              </h2>
            </div>
          )}

          <div
            className={`o-col ${
              is5050
                ? 'o-col-sm-md-6 o-col-lg-5 o-col-lg--offset-1'
                : 'o-col-sm-md-6 o-col-lg--offset-1'
            }`}
            styleName="text-callout__left-col"
          >
            {sliceLabel === '50-50' && (
              <div>
                <h2 className="title--large">
                  {data.primary.portfolio_title.text}
                </h2>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: data.primary.portfolio_body.html,
              }}
              styleName="text-callout__body"
            />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: data.primary.portfolio_sidebar.html,
            }}
            className={`o-col ${
              is5050
                ? 'o-col-sm-md-6 o-col-lg-5'
                : 'o-col-sm-md-6 o-col-md-5 o-col-lg-4'
            }`}
            styleName="text-callout__sidebar"
          />
        </div>
      </div>
    </div>
  );
};

export default TextCallout;
