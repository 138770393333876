import React from 'react';
import Button from '../button';

import './argon-bios.module.scss';

const ArgonBios = ({ content }) => (
  <div className="o-container-fluid u-mar-top-xxxl u-vertical-rhythm">
    <div className="o-row">
      <div className="o-col o-col-lg-10 o-col-lg--offset-1">
        <div styleName="argon-bios">
          <h2 className="title--small">{content.bio_overview.text}</h2>

          {content.bio.map((item, i) => {
            const bio = item.bio_reference.document.data;

            return (
              <div
                styleName={`argon-bios__bio ${
                  i === 0 ? 'argon-bios__bio--left' : 'argon-bios__bio--right'
                }`}
                key={i}
              >
                <div className="u-aspect-ratio is-1x1 u-img-cover u-mar-bottom-s u-bg-purple-dark">
                  <img src={bio.bio_image.fluid.src} alt={bio.bio_image.alt} />
                </div>

                <h3 className="title--xs">{bio.bio_name.text}</h3>

                <p className="u-mar-bottom-xxs">{bio.bio_title.text}</p>

                <div dangerouslySetInnerHTML={{ __html: bio.short_bio.html }} />
              </div>
            );
          })}

          <div styleName="argon-bios__cta">
            <Button
              text={content.bio_cta[0].cta_text}
              url={content.bio_cta[0].cta_link.url}
              theme={'bg'}
              withArrow
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ArgonBios;
