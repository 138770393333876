import React from 'react';

import './homepage-intro.module.scss';

const HomepageIntro = ({ pageContent }) => {
  return (
    <div className="o-container-fluid" styleName="intro">
      <div className="o-row">
        <div className="o-col o-col-sm-md-8 o-col-sm-md--offset-2">
          <h1
            className="title title--xl"
            styleName="intro__title"
            dangerouslySetInnerHTML={{
              __html: pageContent.intro_headline.html,
            }}
          />
        </div>
        <div
          className="o-col o-col-sm-md-6 o-col-sm-md--offset-3"
          styleName="intro__body"
          dangerouslySetInnerHTML={{ __html: pageContent.intro_body.html }}
        />
      </div>
    </div>
  );
};

export default HomepageIntro;
