import React from 'react';
import Button from '../button';

import './large-callout.module.scss';

const LargeCallout = ({ content, theme }) => (
  <div styleName={`large-callout ${theme ? 'large-callout--' + theme : ''}`}>
    <div className="o-col o-col-xs-sm-10 o-col-sm-md-8 o-col-md-7 o-col-xs-sm--offset-1">
      <div
        dangerouslySetInnerHTML={{
          __html: content.document.data.get_in_touch_content.html,
        }}
        styleName="large-callout__content"
      />
      <Button
        text={content.document.data.get_in_touch_cta[0].text}
        url={content.document.data.get_in_touch_cta[0].link.url}
        target="_blank"
        theme={`bg`}
        withArrow
      />
    </div>
  </div>
);

export default LargeCallout;
