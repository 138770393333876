import React from 'react';
import Quotes from '../quotes';

import './argon-overview.module.scss';

const ArgonOverview = ({ content }) => (
  <div className="u-bg-purple-dark u-pad-bottom-xxs sm:u-pad-bottom-s lg:u-pad-bottom-xl u-pos-relative u-overflow-hidden">
    <div className="o-container-fluid">
      <div className="o-row">
        <div
          className="o-col o-col-md-8 o-col-lg--offset-1"
          styleName="argon-overview"
        >
          <h2 className="title--small u-mar-bottom-l">
            {content.company_overview.text}
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: content.company_details.html,
            }}
          />
        </div>
      </div>

      <Quotes data={content.company_quotes.document.data} noContainer />
    </div>

    <div styleName="argon-overview__atom"></div>
  </div>
);

export default ArgonOverview;
