import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HomepageIntro from '../components/homepage-intro';
import TextCallout from '../components/text-callout';
import LargeCallout from '../components/large-callout';
import ArgonBios from '../components/argon-bios';
import ArgonOverview from '../components/argon-overview';

const IndexPage = ({ data }) => {
  const pageContent = data.allPrismicHomepage.edges[0].node.data;

  return (
    <Layout>
      <SEO title="Home" />

      <HomepageIntro pageContent={pageContent} />
      <ArgonBios content={pageContent} />
      <ArgonOverview content={pageContent} />

      <TextCallout data={pageContent.body[0]} />

      <div className="o-container-fluid">
        <LargeCallout
          content={pageContent.homepage_callout}
          theme={'yellow-top'}
        />
      </div>
    </Layout>
  );
};

export default withPreview(IndexPage);

export const query = graphql`
  {
    allPrismicHomepage {
      edges {
        node {
          prismicId
          data {
            intro_headline {
              html
            }
            intro_body {
              html
            }
            bio_overview {
              text
            }
            bio {
              bio_reference {
                document {
                  ... on PrismicBio {
                    data {
                      bio_image {
                        fluid(maxWidth: 800) {
                          ...GatsbyPrismicImageFluid
                        }
                        alt
                      }
                      bio_name {
                        text
                      }
                      bio_title {
                        text
                      }
                      short_bio {
                        html
                      }
                    }
                  }
                }
              }
            }
            bio_cta {
              cta_text
              cta_link {
                url
              }
            }
            company_overview {
              text
            }
            company_details {
              html
            }
            company_quotes {
              document {
                ... on PrismicQuotes {
                  data {
                    quote {
                      quote_text {
                        html
                      }
                      quote_author {
                        html
                      }
                    }
                  }
                }
              }
            }
            body {
              ... on PrismicHomepageBodyTextCallout {
                slice_label
                primary {
                  portfolio_title {
                    text
                  }
                  portfolio_body {
                    html
                  }
                  portfolio_sidebar {
                    html
                  }
                }
              }
            }
            homepage_callout {
              document {
                ... on PrismicGetInTouch {
                  data {
                    get_in_touch_content {
                      html
                    }
                    get_in_touch_cta {
                      text
                      link {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
